<template>
  <div class="pyb-text">
    <div :class="size == 'big' ? 'big-text' : 'small-text'">{{ pybNum }}</div>
    <div
      class="iconfont icon-w_jinbi"
      :class="size == 'big' ? 'big-icon' : 'small-icon'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PybText",
  props: {
    pybNum: {
      default: 0
    },
    size: {
      default: "small"
    }
  }
};
</script>

<style lang="scss" scoped>
.pyb-text {
  display: inline-block;
}
.icon-w_jinbi {
  color: $text-yellow;
  display: inline-block;
}
.big-icon {
  font-size: 35px;
}
.small-icon {
  font-size: 28px;
}
.big-text {
  font-size: 28px;
  display: inline-block;
  // @include font_color("title_color");
  color: $text-green;
  font-weight: bold;
}
.small-text {
  font-size: 20px;
  display: inline-block;
  // @include font_color("title_color");
  color: $text-green;
  font-weight: bold;
  padding: 0 4px;
}
</style>
