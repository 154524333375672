<template>
  <!-- <div>大模拟完成页</div> -->
  <EndTestBackground :testType="3">
    <b-container class="big-test-end">
      <b-row class="pyb-reward-top">
        <b-col>
          <span class="green">{{ userInfo.studentName }}</span
          >同学,恭喜你完成抢滩登陆<span class="green">{{ mockTestName }}！</span>
        </b-col>
      </b-row>
      <b-row class="pyb-reward">
        <b-col
          >本次模拟测评得分:
          <span class="green">{{ result.testScore }}分</span></b-col
        >
      </b-row>
      <b-row class="pyb-reward">
        <b-col>
          获得 测评答题
          <span class="green"
            ><pybWord :pybNum="result.answerRewardNumber"></pybWord
          ></span>
          关卡奖励
          <span class="green"
            ><pybWord :pybNum="result.sprintRewardNumber"></pybWord></span
          >，已纳入你的后勤补给！
        </b-col>
      </b-row>
      <b-row class="pyb-reward">
        <b-col>
          你的体检：超过
          <span class="green">{{ result.classRanking | turnPercentage }}%</span
          >全班已测试人数 超过
          <span class="green"
            >{{ result.schoolRanking | turnPercentage }}%</span
          >
          全校已测试人数 超过
          <span class="green"
            >{{ result.districtRanking | turnPercentage }}%</span
          >
          全区已测试人数
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span class="iconfont icon-tishi orange"></span>当前班级完成测试人数
          <span class="orange">{{ result.classFinishNumber }}</span>
          人，全校完成测试人数
          <span class="orange">{{ result.schoolFinishNumber }}</span>
          人，全区完成测试人数
          <span class="orange">{{ result.districtFinishNumber }}</span> 人
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <GlobalButton
            buttonType="round"
            buttonText="继续闯关"
            class="big-end-form-btn"
            @afterClick="continueSprint"
          >
          </GlobalButton>
        </b-col>
        <b-col class="big-end-form-btn-right">
          <span @click="viewReport">查看报告</span>
        </b-col>
      </b-row>
    </b-container>
  </EndTestBackground>
</template>

<script>
import EndTestBackground from "@/components/backgroung/end-test-background.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import { getEngFinishProgress } from "../../api/english-mock";
import { mapState } from "vuex";

export default {
  data() {
    return {
      result: "",
    };
  },
  components: {
    EndTestBackground,
    pybWord,
    GlobalButton,
  },
  computed: {
    ...mapState(["mockTestName", "userInfo"]),
  },
  mounted() {
    this.finishPage();
  },
  methods: {
    finishPage() {
      const parmeter = { recordExamId: this.$route.query.studentExamId };
      getEngFinishProgress(parmeter).then((res) => {
        console.log("完成页", res);
        this.result = res.data.result;
      });
    },
    continueSprint() {
      // this.$router.replace({
      //   name: "MockBegin",
      //   query: {
      //     recordExamId: this.$route.query.recordExamId,
      //     sprintId: this.$route.query.sprintId,
      //   },
      // });
      if (this.$route.query.isPass) {
        this.$router.replace({
          name: "MockBegin",
          query: {
            recordExamId: this.$route.query.recordExamId,
            sprintId: 7,
            isPass: this.$route.query.isPass,
            
          },
        });
      } else {
        this.$router.replace({
          name: "MockBegin",
          query: {
            recordExamId: this.$route.query.recordExamId,
            sprintId: 7,
          },
        });
      }
    },
    viewReport() {
      this.$router.replace({
        name: "MockReport",
        query: {
          // module: 7, //报告页面背景
          studentExamId: this.$route.query.studentExamId,
          state: 1,
          sprintId: 7,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.big-test-end {
  min-height: 320px;
}
.green {
  color: $text-green;
  font-weight: bold;
}
.orange {
  color: #ffc13b;
  font-weight: bold;
}
.pyb-reward {
  color: $text-light-gray;
  margin-bottom: 2%;
  // font-size: 1.2rem;
}
.pyb-reward-top {
  color: $text-light-gray;
  margin-bottom: 5%;
  // font-size: 1.2rem;
}
.big-end-form-btn {
  margin-top: 20px;
}
.big-end-form-btn-right {
  margin-top: 28px;
  text-align: left;
  color: $text-light-gray;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>