<template>
  <div class="end-back">
    <div class="top-back"></div>
    <div class="top-img">
      <img :src="getImgUrl()" alt="" />
    </div>
    <GlobalButton
      @afterClick="backHome"
      buttonType="iconline"
      icon="icon-fanhui"
      class="back-home-button"
      buttonText="回大本营"
    ></GlobalButton>
    <div>
      <slot>
        <div>元昊 同学，恭喜你完成 中考英语冲刺扫雷尖兵效果测评！</div>
        <div>本次词汇部分的体检得分：</div>
        <div>
          获得 测评答题 <pybWord></pybWord> 关卡奖励
          <pybWord></pybWord> ，已纳入你的后勤补给！
        </div>
        <div>
          <GlobalButton buttonType="round" buttonText="继续闯关"></GlobalButton>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
export default {
  components: {
    GlobalButton,
    pybWord,
  },
  props: {
    moduleId: {
      default: 3,
    },
    testType: {
      default: 1,
    },
  },
  methods: {
    getImgUrl() {
      switch (this.testType) {
        case 1:
          return require("../../assets/images/medal.png");
        case 2:
          return require("../../assets/images/medal_2.png");
        case 3:
          return require("../../assets/images/medal_3.png");
        default:
          return require("../../assets/images/medal.png");
      }
    },
    backHome() {
      this.$router.replace({
        name: "HomeIndex",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.end-back {
  background: white;
  min-height: 100%;
  width: 72%;
  position: relative;
  overflow: hidden;
  padding: 21rem 0 2rem;
  display: flex;
  // flex-direction: column;
  // justify-items: center;
  justify-content: center;
  align-items: center;
}
.top-back {
  width: 125%;
  height: 33vw;
  background: #2cb774;
  border-radius: 50%;
  position: absolute;
  top: -23vw;
}
.top-img {
  position: absolute;
  top: 3rem;
  // left: 28vw;
  img {
    width: 16vw;
  }
}
.back-home-button {
  position: absolute;
  top: 2%;
  right: 1%;
}
</style>>

