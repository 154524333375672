<template>
  <b-button
    :disabled="disabled"
    @click="afterClick"
    :class="[buttonType, buttonColor]"
  >
    <span :class="['iconfont', icon]"></span>
    <span class="button-text">{{ buttonText }}</span>
    <span><slot name="loading"></slot></span>
  </b-button>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    buttonText: {
      default: "确定",
    },
    buttonType: {
      default: "round",
    },
    buttonColor: {
      default: "",
    },
    icon: {
      default: "",
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    afterClick() {
      this.$emit("afterClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.iconline {
  background: none;
  outline: none;
  border: none;
  .button-text {
    margin: 5px;
    border-bottom: 1px solid;
  }
}
.round {
  background: #2cb774;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 5px 21px;
}
.yellow {
  background: #fed852;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 5px 21px;
  font-weight: bold;
}
.yellow:active {
  background-color: #fed852 !important;
  border-color: #fed852 !important;
  outline: none;
  border-radius: 20px;
  padding: 5px 21px;
  font-weight: bold;
}
.yellow:focus {
  box-shadow: none !important;
}
.black {
  color: black;
}
</style>>

